<script>
  export default {
    name: "LocateMeButton",
    props: {
      isAllowedToLocate: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        tooltipContent:
          "To display locations near you, please allow Location Services, or type in your address.",
      };
    },
    computed: {
      buttonAttrs() {
        return {
          type: "button",
          class: "btn btn-secondary search__locate-me",
          "aria-label": "Get my location",
          disabled: !this.isAllowedToLocate,
        };
      },
      iconAttrs() {
        return {
          alt: "Locate Me",
          class: "fa fa-location-arrow",
          style: { marginTop: "4px" },
        };
      },
    },
    methods: {
      onClick() {
        this.$emit("click");
      },
    },
  };
</script>

<template>
  <div>
    <el-tooltip
      v-if="!isAllowedToLocate"
      placement="top-start"
      :content="tooltipContent"
    >
      <template #default>
        <button v-bind="buttonAttrs" v-on:click="onClick">
          <i v-bind="iconAttrs"></i>
        </button>
      </template>
    </el-tooltip>
    <button v-else v-bind="buttonAttrs" v-on:click="onClick">
      <i v-bind="iconAttrs"></i>
    </button>
  </div>
</template>
