<script>
  import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
  import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
  import { allowedGeolocation } from "../mixins/allowedGeolocation";
  import LocateMeButton from "./LocateMeButton.vue";

  export default {
    name: "SearchMapbox",
    components: {
      LocateMeButton,
    },
    props: {
      mapboxProps: {
        type: Array,
        required: true,
      },
      latlon: {
        type: Array,
        required: false,
      },
      searchAddress: {
        type: String,
        required: false,
      },
    },
    mixins: [allowedGeolocation],
    data() {
      return {
        geocoder: null,
        geoMap: null,
      };
    },
    created() {
      let validCountries = document
        .querySelector("#valid-countries")
        .getAttribute("content");
      if (this.geocoder === null && this.mapboxProps.length > 0) {
        this.geoMap = this.mapboxProps[1].map;
        let geocoderParams = {
          accessToken: this.mapboxProps[0],
          mapboxgl: this.mapboxProps[1],
          autocomplete: true,
          placeholder: this.searchAddress
            ? this.searchAddress
            : "Find a location",
          marker: false,
          countries: validCountries,
          types:
            "country,region,postcode,district,place,locality,neighborhood,address", // exclude poi
        };
        // if lat, lon are numeric
        if (
          this.latlon != null &&
          !isNaN(parseFloat(this.latlon[0])) &&
          isFinite(this.latlon[0]) &&
          !isNaN(parseFloat(this.latlon[1])) &&
          isFinite(this.latlon[1])
        ) {
          geocoderParams.proximity = {
            longitude: parseFloat(this.latlon[0]),
            latitude: parseFloat(this.latlon[1]),
          };
        }
        this.geocoder = new MapboxGeocoder(geocoderParams);
        let self = this;
        this.geocoder.on("loading", this.handleResultsLoading);
        this.geocoder.on("result", (results) => {
          if (results.result) {
            const geoInput = this.getGeoInput();
            if (geoInput && geoInput.getAttribute("aria-expanded") === "true") {
              geoInput.setAttribute("aria-expanded", "false");
            }
            let result = results.result;
            let formatted_address = result.place_name;
            let lon = result.geometry.coordinates[0];
            let lat = result.geometry.coordinates[1];
            self.$emit("search", formatted_address, lat, lon);
            // construct custom dataLayer event using a virtual element
            let searchEventObj = document.createElement("div");
            searchEventObj.setAttribute("data-ga", "Search-Locator");
            searchEventObj.setAttribute("data-ga-event", "hard");
            searchEventObj.setAttribute("data-ga-6", formatted_address);
            searchEventObj.setAttribute("data-dl2", "Locator-Search");
            searchEventObj.setAttribute("data-dl2-e201", "Search");
            searchEventObj.setAttribute("data-dl2-e203", "y");
            searchEventObj.setAttribute("data-dl2-e112", formatted_address);
            let updateSearchDLEvent = new CustomEvent("customDataLayerEvent", {
              detail: searchEventObj,
            });
            document.dispatchEvent(updateSearchDLEvent);
          }
        });
      }
    },
    mounted() {
      if (this.geocoder) {
        document
          .getElementById("geocoder")
          .appendChild(this.geocoder.onAdd(this.geoMap));

        // Update placeholder text based on input state.
        const geoInput = this.getGeoInput();
        const suggestionsDiv =
          document.getElementsByClassName("suggestions")[0];
        suggestionsDiv.setAttribute("aria-label", "suggestions");
        suggestionsDiv.setAttribute("role", "listbox");
        suggestionsDiv.setAttribute("id", "suggestions-ul");

        geoInput.setAttribute("aria-autocomplete", "list");
        geoInput.setAttribute("role", "combobox");
        geoInput.setAttribute("aria-haspopup", "listbox");
        geoInput.setAttribute("aria-controls", "suggestions-ul");
        geoInput.setAttribute("aria-expanded", "false");
        geoInput.setAttribute("aria-active-descendant", "");

        geoInput.classList.add("placeholder-bold");
        geoInput.addEventListener("focus", function () {
          geoInput.setAttribute("placeholder", "City or zip code");
          geoInput.classList.remove("placeholder-bold");
        });
        geoInput.addEventListener("blur", function () {
          geoInput.setAttribute("placeholder", "Find a location");
          geoInput.classList.add("placeholder-bold");
        });
      }
    },
    methods: {
      handleResultsLoading() {
        const geoInput = this.getGeoInput();
        if (geoInput && geoInput.getAttribute("aria-expanded") === "false") {
          geoInput.setAttribute("aria-expanded", "true");
        }
      },
      getGeoInput() {
        return document.getElementsByClassName(
          "mapboxgl-ctrl-geocoder--input"
        )[0];
      },
      doLocateMe() {
        if (!this.allowedGeolocation) {
          // Shortcut if the user has not shared their location.
          return;
        }

        let self = this;
        navigator.geolocation.getCurrentPosition(
          function success(pos) {
            const crd = pos.coords;
            self.allowedGeolocation = true;
            self.$emit("search", "Your location", crd.latitude, crd.longitude);
          },
          function error(err) {
            console.warn("Cannot locate the user's current location.");
            console.warn(`ERROR(${err.code}): ${err.message}`);
            self.allowedGeolocation = false;
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      },
    },
  };
</script>
<template>
  <div style="display: flex">
    <div id="geocoder" class="geocoder"></div>
    <div
      data-ga="Locate-Me-Locator"
      data-ga-event="hard"
      data-dl2="Locator-Location-Services"
      data-dl2-e201="Location-Services"
      data-dl2-e203="y"
    >
      <LocateMeButton
        :isAllowedToLocate="allowedGeolocation"
        @click="doLocateMe"
      />
    </div>
  </div>
</template>

<style lang="scss">
  @import "../styles/breakpoints";
  @import "../styles/variables";

  .mapboxgl-ctrl-geocoder {
    width: 100% !important;
    height: 5.6rem !important;
    padding: 0.2rem 0 0 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: transparent;
    margin-right: 0 !important;
    &:focus-within {
      box-shadow: inset 0 0 0 1.5px $primary-text;
    }
    @include media(">=640px") {
      max-width: none !important;
    }
  }

  .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
    top: 18px !important;
    left: 15px !important;
    cursor: pointer;
    z-index: -100 !important;
  }
  .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-loading {
    margin-right: 30px !important;
    margin-top: 10px !important;

    @include media(">=tablet") {
      margin-right: 30px !important;
      margin-top: 10px !important;
    }
  }
  .mapboxgl-ctrl-geocoder--icon-close {
    @include media(">=640px") {
      margin-top: 10px !important;
    }
    @include media(">=tablet") {
      margin-top: 0 !important;
    }
  }
  .mapboxgl-ctrl-geocoder--input {
    font-weight: bold;
    padding-left: 30px;
    margin-right: 10px;
    &:focus-visible {
      outline: none;
      color: $primary-text;
      outline-width: 0;
      -webkit-appearance: none;
      -webkit-focus-ring-color: none;
    }
  }
  .mapboxgl-ctrl-geocoder--button {
    top: 10px !important;
    right: 15px !important;
    color: $primary-text;
    @include media(">=tablet") {
      top: 18px !important;
    }
  }
  .placeholder-bold::-webkit-input-placeholder {
    color: $primary-text;
    font-weight: 600 !important;
  }
  #sidebar-results.locator-sidebar__results-container
    .search
    .btn.btn-secondary {
    border: none;
    background: none;
  }
  .geocoder {
    flex-grow: 1;
  }
  // Move the locate me button towards the end of the search container
  // while creating spaces around the icon
  .search {
    &__locate-me {
      padding-left: 1rem;
      padding-right: 18px;
      margin-right: 0;
      @include media(">=tablet") {
        padding-right: 1rem;
        margin-right: -1rem;
      }
    }
  }
</style>
