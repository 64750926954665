<script>
  /**
   * A location search bar powered by Google Places Autocomplete.
   * See https://developers.google.com/maps/documentation/javascript/places-autocomplete#introduction
   * for documention.
   *
   * The component hopes that the SDK has been loaded elsewhere in the DOM. Specifically, it
   * will attempt to find the SDK <script> tag with the Google maps SDK, and will then wait for the
   * tag to load before attaching the Google autocomplete service to the search input. If it cannot
   * locate the SDK after 10 seconds, the component pulls in the SDK on its own.
   *
   * events:
   *  @search(address_string, latitude, longitude): emitted when the user makes a successful
   *    autocomplete search.
   */
  import { allowedGeolocation } from "../mixins/allowedGeolocation";
  import LocateMeButton from "./LocateMeButton.vue";

  export default {
    name: "SearchGoogle",
    components: {
      LocateMeButton,
    },
    props: {
      searchAddress: {
        type: String,
        required: false,
      },
    },
    mixins: [allowedGeolocation],
    data() {
      return {
        autocompleteState: "loading", // "loading", "ready", or "error",
      };
    },
    created() {
      /**
       * The Google Maps SDK is laoded in lmbrowser.html:loadScript. That method, however, is only
       * called on window.onload and may it can take some time for the window.google to load.
       * On create, an interval will keep checking if window.google is defined. If it is not defined
       * in the DOM from lmbrowser after some time, the component will load the sdk locally. Once
       * window.google is defined, the interval is cleared and initAutocomplete is called to setup
       * the autocomplete.
       */
      const DOM_SDK_LOAD_TIMEOUT = 10000;
      const SHORT_INTERVAL = 100;
      let msElapsed = 0;
      this.mapLoadInterval = setInterval(() => {
        msElapsed += SHORT_INTERVAL;
        // If the DOM still has not loaded the SDK, load it manually.
        if (msElapsed > DOM_SDK_LOAD_TIMEOUT) {
          // Ensure that loadGoogleSDK is not called again, even if the interval is called again
          msElapsed = Number.NEGATIVE_INFINITY;
          this.loadGoogleSDK();
        }
        if (window.google && window.google.maps) {
          // Clear the interval once google maps is loaded
          clearInterval(this.mapLoadInterval);
          this.initAutocomplete();
        }
      }, SHORT_INTERVAL);
    },

    methods: {
      loadGoogleSDK() {
        const accessToken = this.getGoogleSDKToken();
        let googleSDKScript = document.createElement("script");
        googleSDKScript.setAttribute(
          "src",
          `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${accessToken}&libraries=places&callback=initMaps`
        );
        document.head.appendChild(googleSDKScript);
      },
      getGoogleSDKToken() {
        const mapboxTokenMetaTag = document.querySelector(
          "#google-maps-api-key"
        );
        return mapboxTokenMetaTag && mapboxTokenMetaTag.getAttribute("content");
      },
      /**
       * @description Wraps the google.maps.places.Autocomplete input event listener and listens
       * for the enter key. If the enter key is pressed, simulates arrow-down keypress and
       * rebinds the listeners for google autocomplete.
       * See stackoverflow answer https://stackoverflow.com/a/49620828/10050542
       */
      addEnterListenerWrapper(input) {
        // Store the original event listener from google.maps.places.Autocomplete
        const _addEventListener = input.addEventListener;
        // Wrap
        const addEventListenerWrapper = (type, listener) => {
          if (type === "keydown") {
            /* Store existing listener function */
            const _listener = listener;
            listener = (event) => {
              /* Simulate a 'down arrow' keypress if no address has been selected */
              const suggestionSelected =
                document.getElementsByClassName("pac-item-selected").length;
              if (event.key === "Enter" && !suggestionSelected) {
                const e = new KeyboardEvent("keydown", {
                  key: "ArrowDown",
                  code: "ArrowDown",
                  keyCode: 40,
                });
                _listener.apply(input, [e]);
              }
              _listener.apply(input, [event]);
            };
          }
          _addEventListener.apply(input, [type, listener]);
        };

        input.addEventListener = addEventListenerWrapper;
      },
      initAutocomplete() {
        this.autocompleteState = "loading";
        try {
          /* eslint-disable-next-line */
          this.autocomplete = new google.maps.places.Autocomplete(
            this.$refs.autocomplete,
            {
              fields: ["formatted_address", "geometry", "name"],
              types: ["geocode"],
            }
          );
          this.autocomplete.addListener("place_changed", this.onPlaceChange);
          this.addEnterListenerWrapper(this.$refs.autocomplete);
          this.autocompleteState = "ready";
        } catch (error) {
          console.error(error);
          this.autocompleteState = "error";
        }
      },
      handleBlur() {
        const searchInput = document.getElementById("search-input");
        searchInput.value = "";
        let locQuery = this.searchAddress
          ? this.searchAddress
          : "Find a location";
        searchInput.setAttribute("placeholder", locQuery);
        searchInput.classList.add("placeholder-bold");
      },
      handleFocus() {
        const searchInput = document.getElementById("search-input");
        searchInput.focus();
        searchInput.value = "";
        searchInput.setAttribute("placeholder", "City or zip code");
        searchInput.classList.remove("placeholder-bold");
      },
      onPlaceChange() {
        const place = this.autocomplete.getPlace();
        if (place.geometry && place.formatted_address) {
          const lat = place.geometry.location.lat();
          const lon = place.geometry.location.lng();
          const formatted_address = place.formatted_address;
          this.$emit("search", formatted_address, lat, lon);
          // construct custom dataLayer event using a virtual element
          let searchEventObj = document.createElement("div");
          searchEventObj.setAttribute("data-ga", "Search-Locator");
          searchEventObj.setAttribute("data-ga-event", "hard");
          searchEventObj.setAttribute("data-ga-6", formatted_address);
          searchEventObj.setAttribute("data-dl2", "Locator-Search");
          searchEventObj.setAttribute("data-dl2-e201", "Search");
          searchEventObj.setAttribute("data-dl2-e203", "y");
          searchEventObj.setAttribute("data-dl2-e112", formatted_address);
          let updateSearchDLEvent = new CustomEvent("customDataLayerEvent", {
            detail: searchEventObj,
          });
          document.dispatchEvent(updateSearchDLEvent);
        } else {
          // do nothing.
          // TODO: Should we force the user to select the first autocomplete result?
        }
      },
      doLocateMe() {
        if (!this.allowedGeolocation) {
          // Shortcut if the user has not shared their location.
          return;
        }

        let self = this;
        navigator.geolocation.getCurrentPosition(
          function success(pos) {
            const crd = pos.coords;
            self.allowedGeolocation = true;
            self.$emit("search", "Your location", crd.latitude, crd.longitude);
          },
          function error(err) {
            console.warn("Cannot locate the user's current location.");
            console.warn(`ERROR(${err.code}): ${err.message}`);
            self.allowedGeolocation = false;
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          }
        );
      },
    },
    computed: {
      placeholder() {
        return this.searchAddress ? this.searchAddress : "Find a location";
      },
    },
  };
</script>

<template>
  <div style="display: flex">
    <div class="search__search-box" @click="handleFocus">
      <span
        class="business-card__search-icon vsl-icon"
        aria-hidden="true"
      ></span>
      <input
        tabindex="0"
        :class="`search__search-box__search-input ${autocompleteState} placeholder-bold`"
        id="search-input"
        ref="autocomplete"
        v-bind:placeholder="placeholder"
        @focus="handleFocus"
        @blur="handleBlur"
        type="text"
        data-visual-test="locator-search-box"
      />
    </div>
    <div
      data-ga="Locate-Me-Locator"
      data-ga-event="hard"
      data-dl2="Locator-Location-Services"
      data-dl2-e201="Location-Services"
      data-dl2-e203="y"
    >
      <LocateMeButton
        :isAllowedToLocate="allowedGeolocation"
        @click="doLocateMe"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "../styles/breakpoints";
  @import "../styles/variables";

  $border-color: #ccc;
  $search-shadow: inset 0 0 0 1px $border-color;
  $search-shadow-focused: inset 0 0 0 1.5px $primary-text;

  .search {
    background: #ffffff;
    padding: 0 0 1vh 1rem;

    @include media(">=tablet") {
      box-shadow: inset 0 10px 20px #f2f2f2;
      padding: 2.5vh 3rem;
    }

    h1 {
      margin-top: 2%;
      font-size: 26px;
    }

    &__search-box {
      width: 100%;
      height: 5.6rem;
      padding: 0.2rem 0 0 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      color: $primary-text;
      background: #ffffff;
      box-shadow: $search-shadow;

      &:focus-within {
        box-shadow: $search-shadow-focused;

        & .material-icons-outlined {
          color: $primary !important;
          caret-color: $primary;
        }
      }

      &__search-input {
        width: 110%;
        font-weight: bold;
        border: none;
        background: inherit;
        background-color: transparent;
        padding-left: 50px;
        margin-left: -42px;
        text-overflow: ellipsis;
        &:focus-visible {
          outline: none;
          color: $primary-text;
          caret-color: $primary;
          outline-width: 0;
          -webkit-appearance: none;
          -webkit-focus-ring-color: none;
        }
        &:focus {
          outline: none;
          -webkit-appearance: none;
          -webkit-focus-ring-color: none;
        }
      }

      .fa-search {
        margin-right: 0.8rem;
        font-size: 18px;
        cursor: pointer;
        z-index: 3;
      }
    }
    &__tabs {
      display: flex;
      flex-direction: row;

      @include media(">=tablet") {
        display: none;
      }
      & > div {
        flex-grow: 1;
        &:not(:first-of-type) > div {
          // Make sure that all non-first tabs have no left border.
          border-left: none;
        }
      }
    }

    &__tab-label {
      font-weight: bold;
      cursor: pointer;
      border-top: none;
      font-size: 14px;
      color: $primary-text;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      margin-right: 2rem;

      .material-icons-outlined {
        font-size: 1.1em;
      }

      & .fa-caret-down {
        display: block;
        float: right;
        margin-right: 1rem;
        line-height: inherit;
      }
    }
  }

  .filter-label.active::after,
  .sort-label.active::after {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    background-color: $primary;
    border-radius: 100%;
    margin-left: 0.5rem;
  }

  .placeholder-bold::-webkit-input-placeholder {
    color: $primary-text;
    font-weight: 600 !important;
  }
  #sidebar-results.locator-sidebar__results-container
    .search
    .btn.btn-secondary {
    border: none;
    background: none;
  }
  // Move the locate me button towards the end of the search container
  // while creating spaces around the icon
  .search {
    &__locate-me {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
</style>
